import React from 'react'
// nodejs library that concatenates classes
import classnames from 'classnames'
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  Label
} from 'reactstrap'

import * as actions from '../../../store/actions/index'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import ReactBSAlert from 'react-bootstrap-sweetalert'
import { Link } from 'react-router-dom'
import { greetingTextGen } from '../../../variables/general'

class Register extends React.Component {
  state = {
    name: '',
    name_error: false,
    email: '',
    email_error: false,
    password: '',
    password_error: '',
    agree_privacy: false,
    agree_privacy_error: false,
    pass_strength: ''
  }

  hideAlert = () => {
    this.setState({ alert: null })
  }

  onChange = (event) => {
    const {
      target: { name, value }
    } = event
    this.setState({
      [name]: value,
      pass_strength:
        name === 'password'
          ? this.passwordChecker(this.state.password)
          : this.state.pass_strength,
      [name + '_error']: false
    })
  }

  onClickPrivacy = () => {
    this.setState(function (prevState, props) {
      return {
        agree_privacy: !prevState.agree_privacy,
        agree_privacy_error: false
      }
    })
  }

  submitSingUp = () => {
    let err_name = false
    let err_email = false
    let err_password = false
    let err_agree_privacy = false
    let err_count = 0

    if (this.state.name.length < 1) {
      err_name = true
      err_count++
    }
    if (this.state.email.length < 1) {
      err_email = true
      err_count++
    }
    if (this.state.password.length < 6) {
      err_password = true
      err_count++
    }
    if (!this.state.agree_privacy) {
      err_agree_privacy = true
      err_count++
    }

    if (err_count > 0) {
      this.setState({
        name_error: err_name,
        email_error: err_email,
        password_error: err_password,
        agree_privacy_error: err_agree_privacy
      })
    } else {
      this.props.signUpUser(this.state.email, this.state.password, 'email')
    }
  }

  submitSignUpWithGoogle = () => {
    if (!this.state.agree_privacy) {
      this.setState({ agree_privacy_error: true })
    } else {
      this.props.signUpUser(this.state.email, this.state.password, 'google')
    }
  }

  submitSignUpWithFacebook = () => {
    if (!this.state.agree_privacy) {
      this.setState({ agree_privacy_error: true })
    } else {
      this.props.signUpUser(this.state.email, this.state.password, 'facebook')
    }
  }

  passwordChecker = (pass) => {
    var score = 0
    var letters = new Object()
    for (var i = 0; i < pass.length; i++) {
      letters[pass[i]] = (letters[pass[i]] || 0) + 1
      score += 5.0 / letters[pass[i]]
    }
    var variations = {
      digits: /\d/.test(pass),
      lower: /[a-z]/.test(pass),
      upper: /[A-Z]/.test(pass),
      nonWords: /\W/.test(pass)
    }
    var variationCount = 0
    for (var check in variations) {
      variationCount += variations[check] === true ? 1 : 0
    }
    score += (variationCount - 1) * 10

    return score > 80 ? 'Strong' : score > 60 ? 'Good' : 'Weak'
  }

  componentDidMount() {
    this.setState({ greeting_text: greetingTextGen() })
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.error && this.props.error !== prevProps.error) {
      this.setState({
        alert: (
          <ReactBSAlert
            warning
            style={{ display: 'block', marginTop: '300px' }}
            title="Woops, something went wrong"
            onConfirm={this.hideAlert}
            onCancel={this.hideAlert}
            confirmBtnBsStyle="warning"
            confirmBtnText="Ok"
            btnSize="">
            {this.props.error}
          </ReactBSAlert>
        ),
        password: '',
        password2: ''
      })
    }
  }

  facebookSignInButton = () => {
    return (
      <Button
        className="btn-neutral btn-icon btn-social-media"
        color="default"
        onClick={this.submitSignUpWithFacebook}>
        <span className="btn-inner--icon mr-1">
          <img
            alt="..."
            src={require('assets/img/icons/common/facebook.svg')}
          />
        </span>
        <span className="btn-inner--text">Facebook</span>
      </Button>
    )
  }

  googleSignInButton = () => {
    return (
      <Button
        className="btn-neutral btn-icon btn-social-media"
        color="default"
        onClick={this.submitSignUpWithGoogle}>
        <span className="btn-inner--icon mr-1">
          <img alt="..." src={require('assets/img/icons/common/google.svg')} />
        </span>
        <span className="btn-inner--text">Google</span>
      </Button>
    )
  }

  render() {
    let authRedirect = null
    if (this.props.isSignUpSuccess) {
      authRedirect = <Redirect to="/auth/login" />
    }

    if (this.props.loading) {
      return <div />
    }

    return (
      <>
        {this.state.alert}
        {authRedirect}
        <Row>
          <Col
            xs="12"
            md="6"
            className="d-none d-md-block main-container-greeting"
            style={{
              backgroundSize: 'cover',
              backgroundImage: `url(${require('assets/img/theme/login-bg.png')})`
            }}>
            <div className="welcome-register-login">
              {this.state.greeting_text}
            </div>
          </Col>
          <Col xs="12" md="6" className="main-container-register">
            <Container className=" pb-6 ">
              <Row className="justify-content-center m-1 p-4">
                <img
                  alt="..."
                  src={require('assets/img/brand/auth_logo.png')}
                />
              </Row>
              <Row className="justify-content-center">
                <Col md="10" xl="8">
                  <div className="mt-3">
                    <div className="text-left title-pages">Register</div>
                    <div className="text-left sub-title-pages">
                      Welcome to labme
                    </div>
                  </div>
                  <Form role="form">
                    <FormGroup
                      className={classnames({
                        focused: this.state.focusedName
                      })}>
                      <div
                        className={
                          'input-title ' +
                          (this.state.name_error ? 'negative-text' : '')
                        }>
                        Name
                      </div>
                      <InputGroup
                        className={
                          'input-group-merge input-group-alternative input-group-custom ' +
                          (this.state.name_error ? 'input-group-error' : '')
                        }>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-single-02" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          className="input-custom"
                          placeholder="Name"
                          type="text"
                          name="name"
                          value={this.state.name}
                          onChange={this.onChange}
                          onFocus={() => this.setState({ focusedName: true })}
                          onBlur={() => this.setState({ focusedName: false })}
                        />
                      </InputGroup>
                      {this.state.name_error ? (
                        <div className="input-title negative-text">
                          Please enter the name.
                        </div>
                      ) : (
                        ''
                      )}
                    </FormGroup>

                    <FormGroup
                      className={classnames({
                        focused: this.state.focusedEmail
                      })}>
                      <div
                        className={
                          'input-title ' +
                          (this.state.email_error ? 'negative-text' : '')
                        }>
                        Email
                      </div>
                      <InputGroup
                        className={
                          'input-group-merge input-group-alternative input-group-custom ' +
                          (this.state.email_error ? 'input-group-error' : '')
                        }>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-email-83" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          className="input-custom"
                          placeholder="Email"
                          type="email"
                          name="email"
                          value={this.state.email}
                          onChange={this.onChange}
                          onFocus={() => this.setState({ focusedEmail: true })}
                          onBlur={() => this.setState({ focusedEmail: false })}
                        />
                      </InputGroup>
                      {this.state.email_error ? (
                        <div className="input-title negative-text">
                          Please enter the email address.
                        </div>
                      ) : (
                        ''
                      )}
                    </FormGroup>
                    <FormGroup
                      className={classnames({
                        focused: this.state.focusedPassword
                      })}>
                      <div
                        className={
                          'input-title ' +
                          (this.state.email_error ? 'negative-text' : '')
                        }>
                        Password
                      </div>
                      <InputGroup
                        className={
                          'input-group-merge input-group-alternative input-group-custom ' +
                          (this.state.password_error ? 'input-group-error' : '')
                        }>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-lock-circle-open" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          className="input-custom"
                          placeholder="Password"
                          type="password"
                          name="password"
                          value={this.state.password}
                          onChange={this.onChange}
                          onFocus={() =>
                            this.setState({ focusedPassword: true })
                          }
                          onBlur={() =>
                            this.setState({ focusedPassword: false })
                          }
                        />
                      </InputGroup>
                      {this.state.password_error ? (
                        <div className="input-title negative-text">
                          The length of password should be larger than 6.
                        </div>
                      ) : (
                        ''
                      )}
                      {this.state.password.length > 0 ? (
                        <div className="password-strength-text">
                          Password Strength :
                          <span
                            className={
                              this.state.pass_strength === 'Strong'
                                ? 'positive-text'
                                : this.state.pass_strength === 'Good'
                                ? 'borderline-text'
                                : 'negative-text'
                            }>
                            {' '}
                            {this.state.pass_strength}{' '}
                          </span>
                        </div>
                      ) : (
                        ''
                      )}
                    </FormGroup>

                    <div className="text-muted font-italic">
                      <small>{this.state.error_message}</small>
                    </div>
                    <Row className="justify-content-center">
                      <Col className="text-center py-3" lg="12">
                        <Label check className="text-muted">
                          <Input
                            type="checkbox"
                            id="checkbox2"
                            onChange={(e) =>
                              this.setState({
                                agree_privacy: e.target.checked,
                                agree_privacy_error: false
                              })
                            }
                          />
                          You understand and agree to{' '}
                          <a
                            href="https://labme.ai/policies/terms-of-service"
                            target="_blank">
                            Lab Me's Terms and Conditions
                          </a>
                          ,{' '}
                          <a
                            href="https://labme.ai/policies/privacy-policy"
                            target="_blank">
                            Privacy Policy
                          </a>{' '}
                          and{' '}
                          <a
                            href="https://labme.ai/pages/hipaa-privacy-authorization/"
                            target="_blank">
                            HIPAA privacy authorization
                          </a>
                        </Label>
                        {this.state.agree_privacy_error && (
                          <div className="input-title negative-text">
                            Please agree to the terms and conditions.
                          </div>
                        )}
                      </Col>
                    </Row>
                    <div className="text-center">
                      <Button
                        className="mt-4 button-create-register"
                        color="info"
                        type="button"
                        onClick={this.submitSingUp}
                        disabled={this.props.loading}>
                        Create account
                      </Button>
                    </div>
                  </Form>

                  <div className="mt-3">
                    <div className=" text-center mb-4 social-media-text">
                      Or Register with social media
                    </div>

                    <div className="text-center">
                      {this.googleSignInButton()}
                      {this.facebookSignInButton()}
                    </div>
                  </div>

                  <div className=" text-center mt-2 sign-in-text">
                    Have Account Already?
                    <Link to="/login"> Sign In</Link>
                  </div>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.auth.isSigningUp,
    error: state.auth.signUpError,
    isSignUpSuccess: state.auth.signUpSuccess
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    signUpUser: (email, password, provider) =>
      dispatch(actions.signUpUser(email, password, provider))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Register)
