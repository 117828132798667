import Login from 'views/pages/auth/Login.js'
import ResetPassword from 'views/pages/auth/ResetPassword.js'
import Register from 'views/pages/auth/Register.js'

import React from 'react'
const Dashboard = React.lazy(() =>
  import('views/pages/dashboards/Dashboard.js')
)
const Profile = React.lazy(() => import('views/pages/profile/Profile.js'))
const BloodTestReport = React.lazy(() =>
  import('views/pages/bloodtracking/BloodTestReport.js')
)
const KitRegistration = React.lazy(() =>
  import('views/pages/kit/KitRegistration.js')
)
const ShareDashboard = React.lazy(() =>
  import('views/pages/share/ShareDashboard.js')
)
//const BloodVisualization = React.lazy(() => import('views/pages/bloodtracking/BloodVisualization'));
const Medications = React.lazy(() =>
  import('views/pages/selftracking/medication/Medications')
)
const Dailyactivity = React.lazy(() =>
  import('views/pages/selftracking/dailyactivity/Dailyactivity')
)
const AnalyDash = React.lazy(() => import('layouts/AnalyDash'))
const BloodReport = React.lazy(() =>
  import('views/pages/bloodreport/BloodReport.js')
)
const Subscription = React.lazy(() =>
  import('views/pages/subscription/Subscription.js')
)
const Checkout = React.lazy(() => import('views/pages/checkout/Checkout.js'))

const routes = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    icon: 'fa fa-tachometer-alt text-white',
    component: Dashboard,
    layout: '/dashboard'
  },
  {
    path: '/analysis',
    name: 'Analysis',
    icon: 'fa fa-project-diagram text-white',
    component: AnalyDash,
    layout: '/dashboard'
  },
  {
    path: '/summary',
    name: 'Report',
    icon: 'fa fa-vial text-white',
    component: BloodTestReport,
    layout: '/dashboard'
  },
  /* {
    collapse: true,
    name: "Report",
    icon: "fa fa-vial text-white",
    state: "bloodTrackCollapse",
    layout: "/dashboard",
    views: [
      {
        path: "/summary",
        name: "Summary",
        miniName: "S",
        component: BloodTestReport,
        layout: "/dashboard"
      },
      {
        path: "/report",
        name: "Visualization",
        miniName: "V",
        component: BloodReport,
        layout: "/dashboard"
      } 
    ]
  }, */
  {
    collapse: true,
    name: 'Self Tracking',
    icon: 'fa fa-chart-line text-white',
    state: 'selTrackCollapse',
    layout: '/dashboard',
    views: [
      {
        path: '/medications',
        name: 'Medications',
        miniName: 'M',
        component: Medications,
        layout: '/dashboard'
      },
      {
        path: '/dailyactivity',
        name: 'Daily Activities',
        miniName: 'D',
        component: Dailyactivity,
        layout: '/dashboard'
      }
    ]
  },
  /*
  {
    collapse: true,
    name: "Order",
    icon: "ni ni-basket text-info",
    state: "orderCollapse",
    layout: "/dashboard",
    views: [
      {
        path: "/subscriptions",
        name: "Subscriptions",
        miniName: "S",
        component: Login,
        layout: "/dashboard"
      },
      {
        path: "/billing",
        name: "Billing",
        miniName: "B",
        component: ResetPassword,
        layout: "/dashboard"
      }
    ]
  }, */
  /*   {
    path: "/kit",
    name: "Register Your Kit",
    icon: "fas fa-file-signature text-white",
    component: KitRegistration,
    layout: "/dashboard"
  }, */
  {
    path: '/share',
    name: 'Share',
    icon: 'fas fa-share-alt text-white',
    component: ShareDashboard,
    layout: '/dashboard'
  },
  {
    path: '/profile',
    name: 'Profile',
    icon: 'fa fa-user-alt text-white',
    component: Profile,
    layout: '/dashboard'
  },
  {
    path: '/subscription',
    name: 'Subscription',
    icon: 'fa fa-wallet text-white',
    component: Subscription,
    layout: '/dashboard'
  },
  {
    path: '/checkout',
    name: 'Checkout',
    icon: null,
    component: Checkout,
    layout: '/website'
  },
  {
    path: '/login',
    name: 'Login',
    icon: 'ni ni-circle-08 text-white',
    component: Login,
    layout: '/auth'
  },
  {
    path: '/register',
    name: 'Register',
    icon: 'ni ni-circle-08 text-white',
    component: Register,
    layout: '/auth'
  },
  {
    path: '/resetpassword',
    name: 'ResetPassword',
    icon: 'ni ni-circle-08 text-white',
    component: ResetPassword,
    layout: '/auth'
  }
]

export default routes
