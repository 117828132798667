import React from 'react'
// react library for routing
import { NavLink as NavLinkRRD, Link } from 'react-router-dom'
// nodejs library that concatenates classes
import classnames from 'classnames'
// nodejs library to set properties for components
import { PropTypes } from 'prop-types'
// react library that creates nice scrollbar on windows devices
import PerfectScrollbar from 'react-perfect-scrollbar'
// reactstrap components
import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav
} from 'reactstrap'
// actions from the redux store
import * as actions from '../../store/actions'
// connect to map state to props
import { connect } from 'react-redux'
import BeatLoader from 'react-spinners/BeatLoader'

class Sidebar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      collapseOpen: false,
      ...this.getCollapseStates(props.routes)
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    if (
      this.props.portal_url &&
      this.props.portal_url !== prevProps.portal_url
    ) {
      window.location.assign(this.props.portal_url)
    }
  }

  // verifies if routeName is the one active (in browser input)
  activeRoute = (routeName) => {
    return this.props.location.pathname.indexOf(routeName) > -1 ? 'active' : ''
  }
  // makes the sidenav normal on hover (actually when mouse enters on it)
  onMouseEnterSidenav = () => {
    if (!document.body.classList.contains('g-sidenav-pinned')) {
      document.body.classList.add('g-sidenav-show')
    }
  }
  // makes the sidenav mini on hover (actually when mouse leaves from it)
  onMouseLeaveSidenav = () => {
    if (!document.body.classList.contains('g-sidenav-pinned')) {
      document.body.classList.remove('g-sidenav-show')
    }
  }
  // toggles collapse between opened and closed (true/false)
  toggleCollapse = () => {
    this.setState({
      collapseOpen: !this.state.collapseOpen
    })
  }
  // closes the collapse
  closeCollapse = () => {
    this.setState({
      collapseOpen: false
    })
  }
  // this creates the intial state of this component based on the collapse routes
  // that it gets through this.props.routes
  getCollapseStates = (routes) => {
    let initialState = {}
    routes.map((prop, key) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: this.getCollapseInitialState(prop.views),
          ...this.getCollapseStates(prop.views),
          ...initialState
        }
      }
      return null
    })
    return initialState
  }
  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.js - route /admin/regular-forms
  getCollapseInitialState(routes) {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) {
        return true
      } else if (window.location.href.indexOf(routes[i].path) !== -1) {
        return true
      }
    }
    return false
  }
  // this is used on mobile devices, when a user navigates
  // the sidebar will autoclose
  closeSidenav = () => {
    if (window.innerWidth < 1200) {
      this.props.toggleSidenav()
    }
  }
  // this function creates the links and collapses that appear in the sidebar (left menu)
  createLinks = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout !== '/dashboard' || prop.path === '/subscription') {
        return null
      }
      if (prop.redirect) {
        return null
      }
      if (prop.collapse) {
        var st = {}
        st[prop['state']] = !this.state[prop.state]
        return (
          <NavItem key={key}>
            <NavLink
              data-toggle="collapse"
              aria-expanded={this.state[prop.state]}
              className={classnames({
                active: this.getCollapseInitialState(prop.views)
              })}
              onClick={(e) => {
                e.preventDefault()
                this.setState(st)
              }}
            >
              {prop.icon ? (
                <>
                  <i className={prop.icon} />
                  <span className="nav-link-text">{prop.name}</span>
                </>
              ) : prop.miniName ? (
                <>
                  <span className="sidenav-mini-icon"> {prop.miniName} </span>
                  <span className="sidenav-normal"> {prop.name} </span>
                </>
              ) : null}
            </NavLink>
            <Collapse isOpen={this.state[prop.state]}>
              <Nav className="nav-sm flex-column">
                {this.createLinks(prop.views)}
              </Nav>
            </Collapse>
          </NavItem>
        )
      }
      return (
        <NavItem
          className={this.activeRoute(prop.layout + prop.path)}
          key={key}
        >
          <NavLink
            to={prop.layout + prop.path}
            activeClassName=""
            onClick={this.closeSidenav}
            tag={NavLinkRRD}
          >
            {prop.icon !== undefined ? (
              <>
                <i className={prop.icon} />
                <span className="nav-link-text">{prop.name}</span>
              </>
            ) : prop.miniName !== undefined ? (
              <>
                <span className="sidenav-mini-icon"> {prop.miniName} </span>
                <span className="sidenav-normal"> {prop.name} </span>
              </>
            ) : (
              prop.name
            )}
          </NavLink>
        </NavItem>
      )
    })
  }
  render() {
    const { routes, logo } = this.props
    let navbarBrandProps
    if (logo && logo.innerLink) {
      navbarBrandProps = {
        to: logo.innerLink,
        tag: Link
      }
    } else if (logo && logo.outterLink) {
      navbarBrandProps = {
        href: logo.outterLink,
        target: '_blank'
      }
    }
    const scrollBarInner = (
      <div className="scrollbar-inner d-flex flex-column">
        <div className="sidenav-header d-flex align-items-center justify-content-center">
          {document.body.classList.contains('g-sidenav-pinned') && logo ? (
            <NavbarBrand
              {...navbarBrandProps}
              className="d-flex align-items-center justify-content-center mb-n4"
            >
              <img
                alt={logo.imgAlt}
                className="navbar-brand-img"
                src={logo.imgSrc}
              />
            </NavbarBrand>
          ) : (
            <div className="ml-auto">
              <div
                className={classnames('sidenav-toggler d-none d-xl-block', {
                  active: this.props.sidenavOpen
                })}
                onClick={this.props.toggleSidenav}
              >
                <div className="sidenav-toggler-inner">
                  <i className="sidenav-toggler-line" />
                  <i className="sidenav-toggler-line" />
                  <i className="sidenav-toggler-line" />
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="navbar-inner" style={{ fontFamily: 'quicksand' }}>
          <Collapse navbar isOpen={true}>
            <Nav navbar>{this.createLinks(routes)}</Nav>
            {/*             <hr className="my-3" />
            <h6 className="navbar-heading p-0 text-muted">
              <span className="docs-normal">Help Center</span>
              <span className="docs-mini">D</span>
            </h6> */}
            <Nav className="mb-md-3" navbar>
              {/*               <NavItem>
                <NavLink
                  href="https://www.labme.ai/accuracy/"
                  target="_blank"
                >
                  <i className="ni ni-bulb-61" />
                  <span className="nav-link-text">The science</span>
                </NavLink>
              </NavItem> */}
              <NavItem>
                <NavLink
                  href="https://labme.wispform.com/0cd37284"
                  target="_blank"
                >
                  <i className="fa fa-file-contract text-white" />
                  <span className="nav-link-text">Subscription</span>
                </NavLink>
              </NavItem>
              <NavItem style={{ cursor: 'pointer' }}>
                <NavLink
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    this.props.getStripePortal()
                  }}
                  target="_blank"
                >
                  <i className="fa fa-wallet text-white" />
                  <span className="nav-link-text">
                    {this.props.isLoading ? (
                      <BeatLoader size={15} color={'#FFFFFF'} loading={true} />
                    ) : this.props.portal_url ? (
                      'Redirecting...'
                    ) : (
                      'Billing'
                    )}
                  </span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="https://support.labme.ai/" target="_blank">
                  <i className="ni ni-support-16" />
                  <span className="nav-link-text">Request Support</span>
                </NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </div>
        <div
          className="navbar-footer mt-auto"
          style={{ fontFamily: 'quicksand' }}
        >
          <Nav navbar>
            <NavItem>
              <NavLink href="https://support.labme.ai/" target="_blank">
                <i className="fas fa-info-circle" />
                <span className="nav-link-text">Self Help</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#" onClick={this.props.logoutUser}>
                <i className="fas fa-sign-out-alt" />
                <span className="nav-link-text">Log Out</span>
              </NavLink>
            </NavItem>
          </Nav>
        </div>
      </div>
    )
    return (
      <Navbar
        className={
          'sidenav navbar-vertical navbar-expand-xs navbar-dark bg-blue-deep ' +
          (this.props.rtlActive ? '' : 'fixed-left') +
          (document.body.classList.contains('g-sidenav-pinned')
            ? ' m-0 m-lg-2'
            : '')
        }
        onMouseEnter={this.onMouseEnterSidenav}
        onMouseLeave={this.onMouseLeaveSidenav}
      >
        {navigator.platform.indexOf('Win') > -1 ? (
          <PerfectScrollbar>{scrollBarInner}</PerfectScrollbar>
        ) : (
          scrollBarInner
        )}
      </Navbar>
    )
  }
}

Sidebar.defaultProps = {
  routes: [{}],
  toggleSidenav: () => {},
  sidenavOpen: false,
  rtlActive: false
}

Sidebar.propTypes = {
  // function used to make sidenav mini or normal
  toggleSidenav: PropTypes.func,
  // prop to know if the sidenav is mini or normal
  sidenavOpen: PropTypes.bool,
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  // logo
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired
  }),
  // rtl active, this will make the sidebar to stay on the right side
  rtlActive: PropTypes.bool
}

const mapStateToProps = (state) => {
  return {
    isLoggingOut: state.auth.isLoggingOut,
    portal_url: state.stripe.portal_url,
    isLoading: state.stripe.isLoading
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    logoutUser: () => dispatch(actions.logoutUser()),
    getStripePortal: () => dispatch(actions.getStripePortal())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar)
