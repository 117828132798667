import {
  SELFTRACK_REQUEST,
  SELFTRACK_SUCCESS,
  SELFTRACK_FAILURE,
  SELFTRACK_UPDATE_REQUEST,
  SELFTRACK_UPDATE_SUCCESS,
  SELFTRACK_UPDATE_FAILURE,
  LOGOUT_SUCCESS
} from '../actions/actionTypes'

const isEquivalent = (a, b) => {
  // Create arrays of property names
  var aProps = Object.getOwnPropertyNames(a)
  var bProps = Object.getOwnPropertyNames(b)

  // If number of properties is different,
  // objects are not equivalent
  if (aProps.length !== bProps.length) {
    return false
  }

  for (var i = 0; i < aProps.length; i++) {
    var propName = aProps[i]

    // If values of same property are not equal,
    // objects are not equivalent
    if (a[propName] !== b[propName]) {
      return false
    }
  }

  // If we made it this far, objects
  // are considered equivalent
  return true
}

export default (
  state = {
    isLoading: false,
    loadSuccess: false,
    isUpdating: false,
    data: {},
    loadError: null,
    updateError: null,
    updateSuccess: false
  },
  action
) => {
  switch (action.type) {
    case LOGOUT_SUCCESS:
      return {
        isLoading: false,
        loadSuccess: false,
        isUpdating: false,
        data: {},
        loadError: null,
        updateError: null,
        updateSuccess: false
      }
    case SELFTRACK_REQUEST:
      return {
        ...state,
        isLoding: true,
        loadError: null,
        loadSuccess: false
      }
    case SELFTRACK_SUCCESS:
      return {
        ...state,
        isLoding: false,
        loadError: null,
        loadSuccess: true,
        data: action.selftrack
      }
    case SELFTRACK_FAILURE:
      return {
        ...state,
        isLoding: false,
        isUpdating: false,
        loadSuccess: false,
        loadError: action.error
      }
    case SELFTRACK_UPDATE_REQUEST:
      return {
        ...state,
        isUpdating: true,
        updateSuccess: false,
        updateError: null
      }
    case SELFTRACK_UPDATE_SUCCESS:
      let new_data = state.data

      if (action.ops === 'add') {
        new_data = {
          ...state.data,
          [action.path]: state.data[action.path].concat([action.update])
        }
      } else if (action.ops === 'remove') {
        new_data = {
          ...state.data,
          [action.path]: state.data[action.path].filter(
            (item, index) => !isEquivalent(item, action.update)
          )
        }
      } else if (action.ops === 'update') {
        // if it's a map objecct
        let updated = null
        if (Array.isArray(state.data[action.path])) {
          updated = state.data[action.path].map((item) => {
            return item.id === action.update.id ? action.update : item
          })
        } else {
          updated = action.update
        }

        new_data = {
          ...state.data,
          [action.path]: updated
        }
      }
      return {
        ...state,
        isUpdating: false,
        updateError: null,
        updateSuccess: true,
        data: new_data
      }
    case SELFTRACK_UPDATE_FAILURE:
      return {
        ...state,
        isUpdating: false,
        updateSuccess: false,
        updateError: action.error
      }
    default:
      return state
  }
}
