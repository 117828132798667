import React from 'react'
import ReactDOM from 'react-dom'
// react library for routing
import { BrowserRouter } from 'react-router-dom'

// bootstrap rtl for rtl support page
import 'assets/vendor/bootstrap-rtl/bootstrap-rtl.scss'
// plugins styles from node_modules
import 'react-notification-alert/dist/animate.css'
import 'react-perfect-scrollbar/dist/css/styles.css'
import 'assets/css/login.css'
// plugins styles downloaded
import 'assets/vendor/fullcalendar/dist/fullcalendar.min.css'
import 'assets/vendor/sweetalert2/dist/sweetalert2.min.css'
import 'assets/vendor/select2/dist/css/select2.min.css'
import 'assets/vendor/quill/dist/quill.core.css'
import 'assets/vendor/nucleo/css/nucleo.css'
import 'assets/vendor/@fortawesome/fontawesome-free/css/all.min.css'
// core styles
import 'assets/scss/argon-dashboard-pro-react.scss?v1.1.0'
import 'assets/css/custom.css'
//import "assets/css/argon-dashboard-pro-react.min.css";
// redux
import { createStore, compose, applyMiddleware, combineReducers } from 'redux'
import authReducer from './store/reducers/auth'
import kitReducer from './store/reducers/kit'
import profileReducer from './store/reducers/profile'
import summaryReducer from './store/reducers/summary'
import reportReducer from './store/reducers/report'
import selftrackReducer from './store/reducers/selftrack'
import stripeReducer from './store/reducers/stripe'
import uploadReportReducer from './store/reducers/upload_reports'
import { verifyAuth } from './store/actions'
import { Provider } from 'react-redux'
import thunk from 'redux-thunk'
// App
import App from './App'

import { hotjar } from 'react-hotjar'
if (process.env.REACT_APP_STAGE === 'prod') {
  hotjar.initialize(1963107, 6)
}

//To be used when we use middleware
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const rootReducer = combineReducers({
  auth: authReducer,
  kit: kitReducer,
  profile: profileReducer,
  summary: summaryReducer,
  report: reportReducer,
  selftrack: selftrackReducer,
  stripe: stripeReducer,
  uploadReport: uploadReportReducer
})

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)))
store.dispatch(verifyAuth())

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
)
