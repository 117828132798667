import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
  VERIFY_REQUEST,
  VERIFY_SUCCESS,
  SIGNUP_REQUEST,
  SIGNUP_SUCCESS,
  SIGNUP_FAILURE,
  SET_AUTH_REDIRECT_PATH,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE
} from '../actions/actionTypes'

export default (
  state = {
    isLoggingIn: false,
    isLoggingOut: false,
    isSigningUp: false,
    isResetPassword: false,
    isVerifying: false,
    loginError: null,
    logoutError: null,
    signUpError: null,
    resetPasswordError: null,
    signUpSuccess: false,
    verifyingError: null,
    resetPasswordSuccess: false,
    isAuthenticated: false,
    authRedirectPath: '/',
    user: {}
  },
  action
) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        isLoggingIn: true,
        loginError: null
      }
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoggingIn: false,
        isAuthenticated: true,
        user: action.user
      }
    case LOGIN_FAILURE:
      return {
        ...state,
        isLoggingIn: false,
        isAuthenticated: false,
        loginError: action.error
      }
    case LOGOUT_REQUEST:
      return {
        ...state,
        isLoggingOut: true,
        logoutError: null
      }
    case LOGOUT_SUCCESS:
      return {
        ...state,
        isLoggingOut: false,
        isAuthenticated: false,
        user: {}
      }
    case LOGOUT_FAILURE:
      return {
        ...state,
        isLoggingOut: false,
        logoutError: action.error
      }
    case VERIFY_REQUEST:
      return {
        ...state,
        isVerifying: true,
        verifyingError: null
      }
    case VERIFY_SUCCESS:
      return {
        ...state,
        isVerifying: false
      }
    case SIGNUP_REQUEST:
      return {
        ...state,
        isSigningUp: true,
        isAuthenticated: false,
        signUpSuccess: false,
        signUpError: null
      }
    case SIGNUP_SUCCESS:
      return {
        ...state,
        isSigningUp: false,
        isAuthenticated: false,
        signUpSuccess: true
      }
    case SIGNUP_FAILURE:
      return {
        ...state,
        isSigningUp: false,
        isAuthenticated: false,
        signUpSuccess: false,
        signUpError: action.error
      }
    case SET_AUTH_REDIRECT_PATH:
      return {
        ...state,
        authRedirectPath: action.path
      }
    case RESET_PASSWORD_REQUEST:
      return {
        ...state,
        isResetPassword: true,
        resetPasswordError: null,
        resetPasswordSuccess: false
      }
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        isResetPassword: false,
        resetPasswordError: null,
        resetPasswordSuccess: true
      }
    case RESET_PASSWORD_FAILURE:
      return {
        ...state,
        isResetPassword: false,
        resetPasswordError: action.error,
        resetPasswordSuccess: false
      }
    default:
      return state
  }
}
