import {
  PROFILE_UPDATE_SUCCESS,
  PROFILE_LOAD_REQUEST,
  PROFILE_UPDATE_REQUEST,
  PROFILE_LOAD_FAILURE,
  PROFILE_LOAD_SUCCESS,
  PROFILE_UPDATE_FAILURE,
  LOGOUT_SUCCESS,
  SHARE_REPORT_REQUEST,
  SHARE_REPORT_SUCCESS,
  SHARE_REPORT_FAILURE,
  GET_SHARE_REPORT_REQUEST,
  GET_SHARE_REPORT_SUCCESS,
  GET_SHARE_REPORT_FAILURE,
  GET_SHARE_BY_REPORT_REQUEST,
  GET_SHARE_BY_REPORT_SUCCESS,
  GET_SHARE_BY_REPORT_FAILURE,
  PUT_SHARE_REPORT_REQUEST,
  PUT_SHARE_REPORT_SUCCESS,
  PUT_SHARE_REPORT_FAILURE,
  PUT_SHARE_BY_REPORT_REQUEST,
  PUT_SHARE_BY_REPORT_SUCCESS,
  PUT_SHARE_BY_REPORT_FAILURE,
  UNSHARE_REPORT_REQUEST,
  UNSHARE_REPORT_SUCCESS,
  UNSHARE_REPORT_FAILURE
} from '../actions/actionTypes'

export default (
  state = {
    shareIsLoading: false,
    shareSuccess: false,
    shareError: null,
    getShareSuccess: false,
    dashboardLoading: false,
    dashboardLoadingSuccess: false,
    isLoading: false,
    isUpdating: false,
    profile: {},
    loadError: null,
    loadSuccess: false,
    updateError: null,
    updateSuccess: false,
    sharedTo: null,
    sharedFrom: null,
    sharedDashboard: {}
  },
  action
) => {
  switch (action.type) {
    case SHARE_REPORT_REQUEST:
      return {
        ...state,
        shareIsLoading: true,
        shareSuccess: false,
        shareError: null
      }
    case PUT_SHARE_REPORT_REQUEST:
      return {
        ...state,
        shareIsLoading: true,
        updateSuccess: false,
        shareError: null
      }
    case PUT_SHARE_REPORT_SUCCESS:
      return {
        ...state,
        shareIsLoading: false,
        updateSuccess: true,
        shareError: null
      }
    case PUT_SHARE_REPORT_FAILURE:
      return {
        ...state,
        shareIsLoading: false,
        updateSuccess: false,
        shareError: action.error
      }
    case SHARE_REPORT_SUCCESS:
      return {
        ...state,
        shareIsLoading: false,
        shareSuccess: true,
        shareError: null
      }
    case SHARE_REPORT_FAILURE:
      return {
        ...state,
        shareIsLoading: false,
        shareSuccess: false,
        shareError: action.error
      }
    case GET_SHARE_REPORT_SUCCESS:
      return {
        ...state,
        shareIsLoading: false,
        getShareSuccess: true,
        sharedTo: action.sharedTo
      }
    case GET_SHARE_BY_REPORT_REQUEST:
      return {
        ...state,
        shareIsLoading: true,
        getShareSuccess: false,
        shareError: null
      }
    case GET_SHARE_BY_REPORT_SUCCESS:
      return {
        ...state,
        shareIsLoading: false,
        getShareSuccess: true,
        sharedFrom: action.sharedFrom
      }
    case GET_SHARE_BY_REPORT_FAILURE:
      return {
        ...state,
        shareIsLoading: false,
        getShareSuccess: false,
        shareError: action.error
      }
    case PUT_SHARE_BY_REPORT_REQUEST:
      return {
        ...state,
        dashboardLoading: true,
        dashboardLoadingSuccess: null,
        sharedDashboard: null
      }
    case PUT_SHARE_BY_REPORT_SUCCESS:
      return {
        ...state,
        dashboardLoading: false,
        dashboardLoadingSuccess: true,
        sharedDashboard: action.dashboard
      }
    case PUT_SHARE_BY_REPORT_FAILURE:
      return {
        ...state,
        dashboardLoading: false,
        dashboardLoadingSuccess: false,
        shareError: action.error
      }
    case UNSHARE_REPORT_REQUEST:
      return {
        ...state,
        shareIsLoading: true,
        shareSuccess: false,
        shareError: null
      }
    case UNSHARE_REPORT_SUCCESS:
      return {
        ...state,
        shareIsLoading: false,
        shareSuccess: true,
        shareError: null
      }
    case UNSHARE_REPORT_FAILURE:
      return {
        ...state,
        shareIsLoading: false,
        shareSuccess: false,
        shareError: action.error
      }
    case LOGOUT_SUCCESS:
      return {
        isLoading: false,
        isUpdating: false,
        shareIsLoading: false,
        shareSuccess: false,
        shareError: null,
        profile: {},
        loadError: null,
        loadSuccess: false,
        updateError: null,
        updateSuccess: false
      }
    case PROFILE_LOAD_REQUEST:
      return {
        ...state,
        isLoding: true,
        loadSuccess: false,
        loadError: null
      }
    case PROFILE_LOAD_SUCCESS:
      return {
        ...state,
        isLoding: false,
        loadError: null,
        profile: action.profile,
        loadSuccess: true
      }
    case PROFILE_LOAD_FAILURE:
      return {
        ...state,
        isLoding: false,
        isUpdating: false,
        loadError: action.error,
        loadSuccess: false
      }
    case PROFILE_UPDATE_FAILURE:
      return {
        ...state,
        isLoding: false,
        isUpdating: false,
        updateError: action.error,
        updateSuccess: false
      }
    case PROFILE_UPDATE_REQUEST:
      return {
        ...state,
        isUpdating: true,
        updateError: null,
        updateSuccess: false
      }
    case PROFILE_UPDATE_SUCCESS:
      let profile = {}
      if (!action.profile.shipping_address) {
        profile = {
          ...state.profile,
          ...action.profile
        }
      } else {
        profile = {
          ...state.profile,
          ...action.profile,
          shipping_address: {
            ...state.profile.shipping_address,
            ...action.profile.shipping_address
          }
        }
      }

      return {
        ...state,
        isUpdating: false,
        updateError: null,
        updateSuccess: true,
        profile: profile
      }
    default:
      return state
  }
}
