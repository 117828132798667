import {
  STRIPE_PORTAL_FAILURE,
  STRIPE_PORTAL_REQUEST,
  STRIPE_PORTAL_SUCCESS,
  START_SUBSCRIPTION_CHECKOUT_SUCCESS,
  START_SUBSCRIPTION_CHECKOUT_FAILURE,
  START_SUBSCRIPTION_CHECKOUT_REQUEST,
  GET_SUBSCRIPTIONS_FAILURE,
  GET_SUBSCRIPTIONS_REQUEST,
  GET_SUBSCRIPTIONS_SUCCESS,
  GET_PRODUCT_LIST_REQUEST,
  GET_PRODUCT_LIST_SUCCESS,
  GET_PRODUCT_LIST_FAILURE,
  LOGOUT_SUCCESS
} from '../actions/actionTypes'

export default (
  state = {
    isLoading: false,
    isUpdating: false,
    loadError: null,
    loadSuccess: false,
    checkout_session_id: null,
    subscriptions: [],
    portal_url: null,
    products: null
  },
  action
) => {
  switch (action.type) {
    case LOGOUT_SUCCESS:
      return {
        isLoading: false,
        isUpdating: false,
        loadError: null,
        loadSuccess: false,
        checkout_session_id: null,
        subscriptions: [],
        portal_url: null
      }
    case GET_PRODUCT_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
        loadSuccess: false,
        loadError: null
      }
    case GET_PRODUCT_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        loadError: null,
        products: action.products,
        loadSuccess: true
      }
    case GET_PRODUCT_LIST_FAILURE:
      return {
        ...state,
        isLoading: false,
        isUpdating: false,
        loadError: action.error,
        loadSuccess: false,
        products: null
      }
    case START_SUBSCRIPTION_CHECKOUT_REQUEST:
      return {
        ...state,
        isLoading: true,
        loadSuccess: false,
        loadError: null,
        checkout_session_id: null
      }
    case START_SUBSCRIPTION_CHECKOUT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        loadError: null,
        checkout_session_id: action.sessionId,
        loadSuccess: true
      }
    case START_SUBSCRIPTION_CHECKOUT_FAILURE:
      return {
        ...state,
        isLoading: false,
        isUpdating: false,
        loadError: action.error,
        loadSuccess: false,
        checkout_session_id: null
      }
    case GET_SUBSCRIPTIONS_REQUEST:
      return {
        ...state,
        isLoading: true,
        loadSuccess: false,
        loadError: null,
        subscriptions: []
      }
    case GET_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        loadError: null,
        subscriptions: action.subscriptions,
        loadSuccess: true
      }
    case GET_SUBSCRIPTIONS_FAILURE:
      return {
        ...state,
        isLoading: false,
        isUpdating: false,
        loadError: action.error,
        loadSuccess: false,
        subscriptions: []
      }
    case STRIPE_PORTAL_REQUEST:
      return {
        ...state,
        isLoading: true,
        loadSuccess: false,
        loadError: null,
        portal_url: null
      }
    case STRIPE_PORTAL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        loadError: null,
        portal_url: action.data.url,
        loadSuccess: true
      }
    case STRIPE_PORTAL_FAILURE:
      return {
        ...state,
        isLoading: false,
        isUpdating: false,
        loadError: action.error,
        loadSuccess: false,
        portal_url: null
      }
    default:
      return state
  }
}
