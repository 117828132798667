import React from 'react'
import RingLoader from 'react-spinners/RingLoader'

const style = {
  marginLeft: '50%'
}

const Loader = ({ loading }) => (
  <div className="modal-dialog-centered" style={style}>
    <RingLoader size={50} color="#123abc" loading={loading} />
  </div>
)

export default Loader
