import React from 'react'
// nodejs library that concatenates classes
import classnames from 'classnames'
// reactstrap components
import {
  Button,
  Label,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from 'reactstrap'
import { Link } from 'react-router-dom'

import * as actions from '../../../store/actions/index'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import ReactBSAlert from 'react-bootstrap-sweetalert'
import { greetingTextGen } from '../../../variables/general'

class Login extends React.Component {
  state = {
    email: '',
    password: '',
    checked: false
  }

  hideAlert = () => {
    this.setState({ alert: null })
  }
  componentDidMount() {
    this.focusRef = React.createRef()
    this.setState({ greeting_text: greetingTextGen() })
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.error && this.props.error !== prevProps.error) {
      this.setState({
        alert: (
          <ReactBSAlert
            warning
            style={{ display: 'block', marginTop: '300px' }}
            title="Whoops, something went wrong"
            onConfirm={this.hideAlert}
            onCancel={this.hideAlert}
            confirmBtnBsStyle="warning"
            confirmBtnText="Ok"
            btnSize=""
          >
            {this.props.error}
          </ReactBSAlert>
        ),
        email: '',
        password: ''
      })
    }
  }

  onChange = (event) => {
    const {
      target: { name, value }
    } = event
    this.setState({ [name]: value })
  }

  onKeyPress = (event, password) => {
    const { charCode } = event
    /* Condition to evaluate if event has a char code of 13 which means enter key got pressed */
    if (charCode === 13) {
      if (password) {
        //this.submitLogin()
      } else {
        this.focusRef.current.focus()
      }
    }
  }

  submitLogin = () => {
    this.props.loginUser(this.state.email, this.state.password, "email");
  };

  submitLoginWithGoogle = () => {
    if (!this.state.checked) {
      this.setState({
        alert: (
          <ReactBSAlert
            warning
            style={{ display: 'block', marginTop: '300px' }}
            title="Whoops, please make sure to accept our terms before logging in."
            onConfirm={this.hideAlert}
            onCancel={this.hideAlert}
            confirmBtnBsStyle="warning"
            confirmBtnText="Ok"
            btnSize=""
          >
            {this.props.error}
          </ReactBSAlert>
        ),
        email: "",
        password: "",
      });
    } else {
      this.props.loginUser(this.state.email, this.state.password, "google");
    }
  }

  submitLoginWithFacebook = () => {
    if (!this.state.checked) {
      this.setState({
        alert: (
          <ReactBSAlert
            warning
            style={{ display: 'block', marginTop: '300px' }}
            title="Whoops, please make sure to accept our terms before logging in."
            onConfirm={this.hideAlert}
            onCancel={this.hideAlert}
            confirmBtnBsStyle="warning"
            confirmBtnText="Ok"
            btnSize=""
          >
            {this.props.error}
          </ReactBSAlert>
        ),
        email: "",
        password: "",
      });
    } else {
      this.props.loginUser(this.state.email, this.state.password, "facebook");
    }
  }

  facebookSignInButton = () => {
    return (
      <Button
        className="btn-neutral btn-icon"
        color="default"
        onClick={(e) => e.preventDefault()}
      >
        <span className="btn-inner--icon mr-1">
          <img
            alt="..."
            src={require('assets/img/icons/common/facebook.svg')}
          />
        </span>
        <span className="btn-inner--text">Facebook</span>
      </Button>
    )
  }

  googleSignInButton = () => {
    return (
      <Button
        className="btn-neutral btn-icon"
        color="default"
        onClick={this.submitLoginWithGoogle}
      >
        <span className="btn-inner--icon mr-1">
          <img alt="..." src={require('assets/img/icons/common/google.svg')} />
        </span>
        <span className="btn-inner--text">Google</span>
      </Button>
    )
  }

  render() {
    let authRedirect = null
    if (this.props.isAuthenticated) {
      authRedirect = <Redirect to={this.props.authRedirectPath} />;
    }

    if (this.props.loading) {
      return <div />
    }

    return (
      <>
        {this.state.alert}
        {authRedirect}
        <Row>
          <Col xs="12" md="6" className="white-background">
            <Container className="mt-5 pb-5">
              <Row className="justify-content-center m-1 p-4">
                <a target="_blank" href="https://www.labme.ai/">
                  <img
                    alt="..."
                    src={require('assets/img/brand/auth_logo_new.png')}
                    style={{ width: '200px' }}
                  />
                </a>
              </Row>
              <Row className="justify-content-center">
                <Col md="10" xl="8">
                  <div className="mt-3">
                    <div className="text-left title-pages">Sign In</div>
                    <div className="text-left sub-title-pages">
                      Welcome to labme
                    </div>
                  </div>
                  <Form role="form">
                    <FormGroup
                      className={classnames({
                        focused: this.state.focusedEmail
                      })}
                    >
                      <div className="input-title">Email</div>

                      <InputGroup className="input-group-merge input-group-alternative input-group-custom">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="auth-icons-email ni ni-email-83" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Email"
                          type="email"
                          className="input-custom"
                          name="email"
                          autoFocus={true}
                          value={this.state.email}
                          onFocus={() => this.setState({ focusedEmail: true })}
                          onBlur={() => this.setState({ focusedEmail: false })}
                          onChange={this.onChange}
                          onKeyPress={(e) => {
                            this.onKeyPress(e, false)
                          }}
                        />
                      </InputGroup>
                    </FormGroup>

                    <FormGroup
                      className={classnames({
                        focused: this.state.focusedPassword
                      })}
                    >
                      <div className="input-title">Password</div>

                      <InputGroup className="input-group-merge input-group-alternative input-group-custom">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="auth-icons-pass ni ni-lock-circle-open" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Password"
                          type="password"
                          name="password"
                          className="input-custom"
                          value={this.state.password}
                          onFocus={() =>
                            this.setState({ focusedPassword: true })
                          }
                          onBlur={() =>
                            this.setState({ focusedPassword: false })
                          }
                          onChange={this.onChange}
                          onKeyPress={(e) => {
                            this.onKeyPress(e, true)
                          }}
                          innerRef={this.focusRef}
                        />
                      </InputGroup>
                    </FormGroup>
                    <Row className="mt-3">
                      <Col xs="6">
                        <div className="custom-control custom-control-alternative custom-checkbox">
                          <input
                            className="custom-control-input"
                            id="customCheckLogin"
                            type="checkbox"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="customCheckLogin"
                          >
                            <div className="text-center mb-4 social-media-text">
                              Remember me
                            </div>
                          </label>
                        </div>
                      </Col>
                      <Col className="text-right" xs="6">
                        <Link
                          to="/auth/resetpassword"
                          style={{ color: '#568ea6' }}
                        >
                          <div className="forgot-pass">Forgot password?</div>
                        </Link>
                      </Col>
                    </Row>
                    <Row className="justify-content-center">
                      <Col className="text-center py-3" lg="12">
                        <Label check className="text-muted">
                          <Input
                            type="checkbox"
                            id="checkbox2"
                            onChange={(e) =>
                              this.setState({ checked: e.target.checked })
                            }
                          />
                          You understand and agree to{' '}
                          <a
                            href="https://labme.ai/policies/terms-of-service"
                            target="_blank"
                          >
                            Lab Me's Terms and Conditions
                          </a>
                          ,{' '}
                          <a
                            href="https://labme.ai/policies/privacy-policy"
                            target="_blank"
                          >
                            Privacy Policy
                          </a>{' '}
                          and{' '}
                          <a
                            href="https://labme.ai/pages/hipaa-privacy-authorization/"
                            target="_blank"
                          >
                            HIPAA privacy authorization
                          </a>
                        </Label>
                      </Col>
                    </Row>
                    <div className="text-center">
                      <Button
                        className="my-4 signin-btn"
                        color="info"
                        type="button"
                        onClick={this.submitLogin}
                        disabled={this.props.loading || !this.state.checked}
                      >
                        Sign in
                      </Button>
                    </div>

                    <div className="mt-3">
                      <div className=" text-center mb-4 social-media-text">
                        Or Sign In with Google
                      </div>
                      <div className="text-center">
                        {/*    <Button
                          className="btn-neutral btn-icon btn-social-media"
                          color="default"
                          onClick={this.submitLoginWithFacebook}
                          disabled={this.props.loading}
                        >
                          <span className="btn-inner--icon mr-1">
                            <img
                              alt="..."
                              src={require("assets/img/icons/common/f.svg")}
                            />
                          </span>
                          <span className="btn-inner--text social-style">
                            Facebook
                          </span>
                        </Button> */}
                        <Button
                          className="btn-neutral btn-icon btn-social-media"
                          color="default"
                          onClick={this.submitLoginWithGoogle}
                          disabled={this.props.loading}
                        >
                          <span className="btn-inner--icon mr-1">
                            <img
                              alt="..."
                              src={require('assets/img/icons/common/g.svg')}
                            />
                          </span>
                          <span className="btn-inner--text social-style">
                            Google
                          </span>
                        </Button>
                      </div>
                    </div>

                  </Form>
                  <Row className="mt-3">
                    <Col xs="3"></Col>
                    <Col xs="6">
                      <Link to="/auth/register">
                        <div className="text-center mb-4 social-media-text">
                          Don't Have Account Yet?
                          <large className="signup-text"> Sign Up</large>
                        </div>
                      </Link>
                    </Col>
                    <Col xs="3"></Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </Col>
          <Col
            xs="12"
            md="6"
            className="d-none d-md-block"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              minHeight: '100vh',
              maxWidth: '100wh',
              backgroundSize: 'cover',
              backgroundImage: `url(${require('assets/img/theme/login-bg.png')})`
            }}
          >
            <div
              className="text-center middle-login-text"
              style={{
                color: 'white',
                fontSize: '24px',
                fontWeight: '700',
                lineHeight: '35'
              }}
            >
              {this.state.greeting_text}
            </div>
          </Col>
        </Row>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.auth.isLoggingIn,
    error: state.auth.loginError,
    isAuthenticated: state.auth.isAuthenticated,
    authRedirectPath: state.auth.authRedirectPath
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loginUser: (email, password, provider) =>
      dispatch(actions.loginUser(email, password, provider))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
