import React from 'react'
// nodejs library that concatenates classes
import classnames from 'classnames'
// nodejs library to set properties for components
import PropTypes from 'prop-types'
// reactstrap components
import {
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Navbar,
  Nav,
  Container,
  NavItem
} from 'reactstrap'
import * as actions from '../../store/actions'
import { connect } from 'react-redux'
import NotificationAlert from 'react-notification-alert'

class AdminNavbar extends React.Component {
  notify = (type, message) => {
    let options = {
      place: 'tc',
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {' '}
            Notify
          </span>
          <span data-notify="message">{message}</span>
        </div>
      ),
      type: type,
      icon: 'ni ni-bell-55',
      autoDismiss: 7
    }
    this.refs.notificationAlert.notificationAlert(options)
  }

  componentDidMount() {
    if (Object.keys(this.props.profile).length <= 0) {
      this.props.getProfile(this.props.user.uid)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.isLoggingOut &&
      this.props.isLoggingOut !== prevProps.isLoggingOut
    ) {
      this.notify('info', 'Logging out.')
    }
  }

  // function that on mobile devices makes the search open
  openSearch = () => {
    document.body.classList.add('g-navbar-search-showing')
    setTimeout(function () {
      document.body.classList.remove('g-navbar-search-showing')
      document.body.classList.add('g-navbar-search-show')
    }, 150)
    setTimeout(function () {
      document.body.classList.add('g-navbar-search-shown')
    }, 300)
  }
  // function that on mobile devices makes the search close
  closeSearch = () => {
    document.body.classList.remove('g-navbar-search-shown')
    setTimeout(function () {
      document.body.classList.remove('g-navbar-search-show')
      document.body.classList.add('g-navbar-search-hiding')
    }, 150)
    setTimeout(function () {
      document.body.classList.remove('g-navbar-search-hiding')
      document.body.classList.add('g-navbar-search-hidden')
    }, 300)
    setTimeout(function () {
      document.body.classList.remove('g-navbar-search-hidden')
    }, 500)
  }
  render() {
    return (
      <>
        <div className="rna-wrapper">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <Navbar
          className={classnames(
            'navbar-top navbar-expand',
            { 'navbar-dark bg-info': this.props.theme === 'dark' },
            { 'navbar-light bg-color': this.props.theme === 'light' }
          )}
        >
          <Container fluid>
            <Collapse navbar isOpen={true}>
              <div className="flex-column d-none d-md-flex">
                {window?.location?.pathname === '/dashboard/dashboard' && (
                  <>
                    <div className="navbar-welcome">
                      Hello {this.props.profile.first_name}, welcome back!
                    </div>
                    <div className="navbar-updated">
                      Your Dashboard is updated
                    </div>
                  </>
                )}
              </div>

              <Nav className="align-items-center ml-md-auto" navbar>
                <NavItem>
                  <div
                    className={classnames(
                      'pr-3 sidenav-toggler',
                      { active: this.props.sidenavOpen },
                      { 'sidenav-toggler-dark': this.props.theme === 'dark' }
                    )}
                    onClick={this.props.toggleSidenav}
                  >
                    <div className="sidenav-toggler-inner">
                      <i className="navbar-top-toggler-line" />
                      <i className="navbar-top-toggler-line" />
                      <i className="navbar-top-toggler-line" />
                    </div>
                  </div>
                </NavItem>
              </Nav>
              <Nav className="align-items-center ml-auto ml-md-0" navbar>
                <NavItem>
                  <Media className="align-items-center">
                    <span className="avatar bg-blue avatar-sm rounded-circle">
                      <i className="ni ni-single-02" />
                    </span>
                    <Media className="ml-2 d-none d-lg-block">
                      <span className="mb-0 text-sm font-weight-bold">
                        {this.props.name}
                      </span>
                    </Media>
                  </Media>
                </NavItem>
              </Nav>
              <Nav className="align-items-center ml-1 ml-lg-0" navbar>
                <UncontrolledDropdown nav>
                  <DropdownToggle className="nav-link p-0" color="" tag="a">
                    <h5
                      className="m-0"
                      style={{ fontFamily: 'quicksand', fontWeight: 500 }}
                    >
                      Welcome, {this.props.profile.first_name}{' '}
                      <i className="fas fa-angle-down pl-1"></i>
                    </h5>
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem className="noti-title" header tag="div">
                      <h6
                        style={{ fontFamily: 'quicksand' }}
                        className="text-overflow m-0"
                      >
                        Welcome!
                      </h6>
                    </DropdownItem>
                    <DropdownItem
                      onClick={(e) =>
                        this.props.history.push('/dashboard/profile')
                      }
                      style={{ fontFamily: 'quicksand' }}
                    >
                      <i className="ni ni-single-02" />
                      <span>My profile</span>
                    </DropdownItem>
                    <DropdownItem
                      href="https://support.labme.ai/"
                      style={{ fontFamily: 'quicksand' }}
                      target="_blank"
                    >
                      <i className="ni ni-support-16" />
                      <span>Support</span>
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem
                      onClick={this.props.logoutUser}
                      style={{ fontFamily: 'quicksand' }}
                    >
                      <i className="ni ni-user-run" />
                      <span>Logout</span>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Nav>
            </Collapse>
          </Container>
        </Navbar>
      </>
    )
  }
}
AdminNavbar.defaultProps = {
  toggleSidenav: () => {},
  sidenavOpen: false,
  theme: 'dark'
}
AdminNavbar.propTypes = {
  toggleSidenav: PropTypes.func,
  sidenavOpen: PropTypes.bool,
  theme: PropTypes.oneOf(['dark', 'light'])
}

const mapStateToProps = (state) => {
  return {
    isLoggingOut: state.auth.isLoggingOut,
    profile: state.profile.profile,
    user: state.auth.user
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    logoutUser: () => dispatch(actions.logoutUser()),
    getProfile: (uid) => dispatch(actions.getProfile(uid))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminNavbar)
