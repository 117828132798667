import {
  KIT_FAILURE,
  KIT_REQUEST,
  KIT_SUCCESS,
  KIT_STEP_SUCCESS,
  KIT_STEP_UPDATE
} from '../actions/actionTypes'

export default (
  state = {
    isLoading: false,
    isUpdating: false,
    loadError: null,
    loadSuccess: false,
    stepSuccess: false,
    kitError: false,
    kit: {}
  },
  action
) => {
  switch (action.type) {
    case KIT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        loadError: null,
        loadSuccess: true,
        stepSuccess: false,
        kitError: false,
        kit: action.kit
      }
    case KIT_STEP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        loadError: null,
        loadSuccess: true,
        stepSuccess: true,
        kitError: false
      }
    case KIT_FAILURE:
      return {
        ...state,
        isLoading: false,
        isUpdating: false,
        loadError: action.error,
        loadSuccess: false,
        kitError: true
      }
    case KIT_REQUEST:
      return {
        ...state,
        isLoading: true,
        loadSuccess: false,
        loadError: null,
        stepSuccess: false,
        kitError: false
      }
    case KIT_STEP_UPDATE:
      return {
        ...state,
        isLoading: false,
        loadSuccess: true,
        stepSuccess: true,
        loadError: null,
        kit: action.kit,
        kitError: false
      }
    default:
      return state
  }
}
