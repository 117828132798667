import * as actionTypes from './actionTypes'
import { myFirebase } from '../../firebase/firebase'

const getProductsRequest = () => {
  return {
    type: actionTypes.GET_PRODUCT_LIST_REQUEST
  }
}

// TODO: need to update later
const getProductesSuccess = (data) => {
  return {
    type: actionTypes.GET_PRODUCT_LIST_SUCCESS,
    products: data
  }
}

const getProductsFailure = (error) => {
  return {
    type: actionTypes.GET_PRODUCT_LIST_FAILURE,
    error: error
  }
}

/*export const getProducts = (uid) => dispatch => {
    dispatch(getProductsRequest());
    myFirebase.firestore().collection('products')
        .where('active', '==', true)
        .get()
        .then(doc => {
        if (doc.exists) {
            dispatch(getProductesSuccess(doc.data()))
        } else {
            console.log("No data presented for " + uid);
            dispatch(getProductsFailure(null));
        }
    }).catch(error => {
        console.log(error);
        dispatch(getProductsFailure(error.message))
    })
}*/

// Start a subscription with Stripe Checkout
const startSubscriptionCheckoutRequest = () => {
  return {
    type: actionTypes.START_SUBSCRIPTION_CHECKOUT_REQUEST
  }
}

const startSubscriptionCheckoutSucess = (sessionId) => {
  return {
    type: actionTypes.START_SUBSCRIPTION_CHECKOUT_SUCCESS,
    sessionId: sessionId
  }
}

const startSubscriptionCheckoutFailure = (error) => {
  return {
    type: actionTypes.START_SUBSCRIPTION_CHECKOUT_FAILURE,
    error: error
  }
}

export const startSubscriptionCheckout =
  (uid, price_id, product_id) => async (dispatch) => {
    dispatch(startSubscriptionCheckoutRequest())

    const docRef = await myFirebase
      .firestore()
      .collection('customers')
      .doc(uid)
      .collection('checkout_sessions')
      .add({
        price: price_id,
        allow_promotion_codes: true,
        // tax_rates: ['txr_1HCjzTHYgolSBA35m0e1tJN5'],
        success_url: window.location.origin,
        cancel_url: window.location.origin,
        metadata: {
          product_id: product_id,
          price_id: price_id
        }
      })

    // Wait for the CheckoutSession to get attached by the extension
    docRef.onSnapshot((snap) => {
      const { error, sessionId } = snap.data()
      if (error) {
        dispatch(startSubscriptionCheckoutFailure(error.message))
      }
      if (sessionId) {
        console.log(sessionId)
        dispatch(startSubscriptionCheckoutSucess(sessionId))
      }
    })
  }

// Get the customer’s subscription
const getSubscriptionsRequest = () => {
  return {
    type: actionTypes.GET_SUBSCRIPTIONS_REQUEST
  }
}

const getSubscriptionsSuccess = (data) => {
  return {
    type: actionTypes.GET_SUBSCRIPTIONS_SUCCESS,
    subscriptions: data
  }
}

const getSubscriptionsFailure = (error) => {
  return {
    type: actionTypes.GET_SUBSCRIPTIONS_FAILURE
  }
}

export const getSubscriptions = (uid) => (dispatch) => {
  dispatch(getSubscriptionsRequest())
  myFirebase
    .firestore()
    .collection('customers')
    .doc(uid)
    .collection('subscriptions')
    .where('status', 'in', ['trialing', 'active'])
    .onSnapshot(
      async (snapshot) => {
        let subscriptions = []
        let priceExist = true
        snapshot.forEach(function (doc) {
          subscriptions.push(doc.data())
        })

        for (let i = 0; i < subscriptions.length; i++) {
          const priceData = await subscriptions[i].price.get()
          if (!priceData.exists) {
            priceExist = false
          } else {
            subscriptions[i]['priceData'] = priceData.data()
          }
        }

        if (!priceExist) {
          subscriptions = []
        }
        dispatch(getSubscriptionsSuccess(subscriptions))
      },
      function (error) {
        dispatch(getSubscriptionsFailure(error.message))
      }
    )
}

// Redirect to the customer portal
const getStripePortalRequest = () => {
  return {
    type: actionTypes.STRIPE_PORTAL_REQUEST
  }
}

const getStripePortalSuccess = (data) => {
  return {
    type: actionTypes.STRIPE_PORTAL_SUCCESS,
    data: data.data
  }
}

const getStripePortalFailure = (error) => {
  return {
    type: actionTypes.STRIPE_PORTAL_FAILURE,
    error: error
  }
}

export const getStripePortal = () => (dispatch) => {
  dispatch(getStripePortalRequest())
  const functionRef = myFirebase
    .functions('us-central1')
    .httpsCallable('ext-firestore-stripe-subscriptions-createPortalLink')
  functionRef({ returnUrl: window.location.origin })
    .then((data) => {
      dispatch(getStripePortalSuccess(data))
    })
    .catch((error) => {
      console.log(error)
      dispatch(getStripePortalFailure(error.message))
    })
}

export const getProductList = () => (dispatch) => {
  dispatch(getProductsRequest())
  const functionRef = myFirebase
    .functions('us-central1')
    .httpsCallable('getProductList')
  functionRef({})
    .then((data) => {
      dispatch(getProductesSuccess(data.data))
    })
    .catch((error) => {
      console.log(error)
      dispatch(getProductsFailure(error.message))
    })
}

export const sendCheckoutForm =
  (
    email,
    phone,
    first_name,
    last_name,
    product_id,
    price_id,
    gender,
    dob,
    line1,
    line2,
    city,
    state,
    zipcode
  ) =>
  (dispatch) => {
    dispatch(startSubscriptionCheckoutRequest())
    const functionRef = myFirebase
      .functions('us-central1')
      .httpsCallable('getCheckoutSessionIdFromStripe')
    functionRef({
      email: email,
      phone: phone,
      first_name: first_name,
      last_name: last_name,
      product_id: product_id,
      price_id: price_id,
      gender: gender,
      dob: dob,
      line1: line1,
      line2: line2,
      city: city,
      state: state,
      zipcode: zipcode,
      returnUrl: window.location.origin
    })
      .then((sessionId) => {
        dispatch(startSubscriptionCheckoutSucess(sessionId.data))
      })
      .catch((error) => {
        console.log(error)
        dispatch(startSubscriptionCheckoutFailure(error.message))
      })
  }
