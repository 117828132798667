import React from 'react'
// reactstrap components
import { Input } from 'reactstrap'

const PhoneInput = (props) => {
  const { name, state, onChange, id, type, placeholder } = props

  const formatPhone = (event) => {
    const {
      target: { value }
    } = event

    // only allows 0-9 inputs
    const currentValue = value.replace(/[^\d]/g, '')
    const cvLength = currentValue.length

    if (!state || value.length > state?.length) {
      // returns: "x", "xx", "xxx"
      if (cvLength < 4) event.target.value = currentValue

      // returns: "(xxx)", "(xxx) x", "(xxx) xx", "(xxx) xxx",
      if (cvLength < 7)
        event.target.value = `(${currentValue.slice(
          0,
          3
        )}) ${currentValue.slice(3)}`

      // returns: "(xxx) xxx-", (xxx) xxx-x", "(xxx) xxx-xx", "(xxx) xxx-xxx", "(xxx) xxx-xxxx"
      event.target.value = `(${currentValue.slice(0, 3)}) ${currentValue.slice(
        3,
        6
      )}-${currentValue.slice(6, 10)}`
    }

    onChange(event)
  }

  //----------------------------------------------------------------------------------------------

  return (
    <Input
      value={state || ''}
      name={name}
      onChange={formatPhone}
      id={id}
      type={type}
      placeholder={placeholder || ''}
    />
  )
}

export default PhoneInput
