import React from 'react'

import { Route, Switch, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'

import AdminLayout from 'layouts/Admin.js'
//import IndexView from "views/Index.js";
import AuthLayout from 'layouts/Auth'
//import Checkout from "views/pages/checkout/Checkout";
import CheckoutV2 from 'views/pages/checkout/CheckoutV2'
import CheckoutSuccess from 'views/pages/checkout/CheckoutSuccess'
import CheckoutFail from 'views/pages/checkout/CheckoutFail'
import * as actions from './store/actions'

// http://localhost:3000/checkout/prod_I0pFSNvUoK3RX6&monthly
// http://localhost:3000/checkoutproduct/prod_I0pFSNvUoK3RX6&monthly
class App extends React.Component {
  componentDidMount() {
    this.props.validateAuthRedirectPath(window.location.pathname)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isAuthenticated !== this.props.isAuthenticated && this.props.isAuthenticated) {
      this.props.setAuthRedirectPath('/dashboard/dashboard');
    }

  }

  render() {
    const { isAuthenticated, isVerifying } = this.props

    let routes = (
      <Switch>
        <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
        <Route path="/checkout/:id" component={CheckoutV2} />
        <Route path="/paymentsuccess" component={CheckoutSuccess} />
        <Route path="/paymentfail" component={CheckoutFail} />
        <Redirect to="/auth/login" />
      </Switch>
    )

    if (isAuthenticated) {
      routes = (
        <Switch>
          <Route
            path="/dashboard"
            render={(props) => <AdminLayout {...props} />}
          />
          <Route path="/checkout/:id" component={CheckoutV2} />
          <Route path="/paymentsuccess" component={CheckoutSuccess} />
          <Route path="/paymentfail" component={CheckoutFail} />
          <Redirect to="/dashboard" />
        </Switch>
      )
    }

    if (isVerifying) {
      return <div></div>
    }

    return <div>{routes}</div>
  }
}

function mapStateToProps(state) {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    isVerifying: state.auth.isVerifying,
    authRedirectPath: state.auth.authRedirectPath
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    validateAuthRedirectPath: (path) =>
      dispatch(actions.validateAuthRedirectPath(path)),
    setAuthRedirectPath: (path) => dispatch(actions.setAuthRedirectPath(path)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App)
