import * as actionTypes from './actionTypes'
import { myFirebase } from '../../firebase/firebase'

const requestProfile = () => {
  return {
    type: actionTypes.PROFILE_LOAD_REQUEST
  }
}

const requestProfileUpdate = () => {
  return {
    type: actionTypes.PROFILE_UPDATE_REQUEST
  }
}

const receiveProfile = (profile) => {
  return {
    type: actionTypes.PROFILE_LOAD_SUCCESS,
    profile
  }
}

const profileLoadError = (error) => {
  return {
    type: actionTypes.PROFILE_LOAD_FAILURE,
    error: error
  }
}

const profileUpdateError = (error) => {
  return {
    type: actionTypes.PROFILE_UPDATE_FAILURE,
    error: error
  }
}

const saveProfile = (data) => {
  return {
    type: actionTypes.PROFILE_UPDATE_SUCCESS,
    profile: data
  }
}

const saveSharedTo = (sharedTo) => {
  return {
    type: actionTypes.GET_SHARE_REPORT_SUCCESS,
    sharedTo
  }
}

const saveSharedFrom = (sharedFrom) => {
  return {
    type: actionTypes.GET_SHARE_BY_REPORT_SUCCESS,
    sharedFrom
  }
}

const requestShareReport = () => {
  return {
    type: actionTypes.SHARE_REPORT_REQUEST
  }
}

const updateSharedReport = () => {
  return {
    type: actionTypes.PUT_SHARE_REPORT_REQUEST
  }
}

const updateSharedReportSuccess = () => {
  return {
    type: actionTypes.PUT_SHARE_REPORT_SUCCESS
  }
}

const updateSharedReportError = (error) => {
  return {
    type: actionTypes.PUT_SHARE_REPORT_FAILURE,
    error
  }
}

const requestShareReportSuccess = () => {
  return {
    type: actionTypes.SHARE_REPORT_SUCCESS
  }
}

const requestShareReportError = (error) => {
  return {
    type: actionTypes.SHARE_REPORT_FAILURE,
    error
  }
}

const requestUnshareReport = () => {
  return {
    type: actionTypes.UNSHARE_REPORT_REQUEST
  }
}

const requestSharedDashboard = () => {
  return {
    type: actionTypes.PUT_SHARE_BY_REPORT_REQUEST
  }
}

const requestSharedDashboardSuccess = (dashboard) => {
  return {
    type: actionTypes.PUT_SHARE_BY_REPORT_SUCCESS,
    dashboard
  }
}

const requestSharedDashboardError = (error) => {
  return {
    type: actionTypes.PUT_SHARE_BY_REPORT_FAILURE,
    error
  }
}

const requestSharedByDashboard = () => {
  return {
    type: actionTypes.GET_SHARE_BY_REPORT_REQUEST
  }
}

const requestSharedByDashboardSuccess = (sharedFrom) => {
  return {
    type: actionTypes.GET_SHARE_BY_REPORT_SUCCESS,
    sharedFrom
  }
}

const requestSharedByDashboardError = (error) => {
  return {
    type: actionTypes.GET_SHARE_BY_REPORT_FAILURE,
    error
  }
}

const requestUnshareReportSuccess = () => {
  return {
    type: actionTypes.UNSHARE_REPORT_SUCCESS
  }
}

const requestUnshareReportError = (error) => {
  return {
    type: actionTypes.UNSHARE_REPORT_FAILURE,
    error
  }
}

/**
 * share report to an email or known uid. You need to specify either email or shareUid.
 * If you specify both, it will ONLY take email.
 * @param {string} email
 * @param {string} shareUid
 * @param {string} alias required
 * @returns
 */
export const shareReport = (email, shareUid, alias, uid) => (dispatch) => {
  dispatch(requestShareReport())
  let update_function = myFirebase.functions().httpsCallable('shareReport')
  update_function({
    email: email,
    shareUid: shareUid,
    alias: alias
  })
    .then(() => {
      // TODO: add it into the cache?
      dispatch(requestShareReportSuccess())
      dispatch(getListUserSharedReportToMe(uid))
    })
    .catch((error) => {
      console.log('err', error)
      dispatch(requestShareReportError(error.message))
    })
}

/**
 * Unshare report with user
 * @param unshared_uid (required)
 * @returns
 */
export const unshareReport = (unshared_uid, uid) => (dispatch) => {
  // TODO: add a new method
  dispatch(requestUnshareReport())
  let update_function = myFirebase
    .functions()
    .httpsCallable('unshareReportWithUserId')
  update_function({ unshareUid: unshared_uid })
    .then(() => {
      // TODO: remove it from the cache?
      dispatch(requestUnshareReportSuccess())
      dispatch(getListUserSharedReportToMe(uid))
    })
    .catch((error) => {
      console.log('share remove error', error)
      // TODO: error handle
      dispatch(requestUnshareReportError(error.message))
    })
}

/**
 * Update the alias for the user which shared report with
 * @param {string} updateUid
 * @param {string} alias
 */
export const updateShareReportAlias =
  (updateUid, alias, uid, collectionName) => (dispatch) => {
    // TODO: add a new method
    dispatch(updateSharedReport())
    let update_function = myFirebase
      .functions()
      .httpsCallable('updateShareReportAlias')
    update_function({
      updateUid: updateUid,
      alias: alias,
      collection: collectionName
    })
      .then(() => {
        // TODO: update in the cache?
        dispatch(updateSharedReportSuccess())
        if (collectionName === 'shareTo') {
          dispatch(getListUserSharedReportToMe(uid))
        } else {
          dispatch(getListUserShareReportFromOthers(uid))
        }
      })
      .catch((error) => {
        console.log('Error alias', error)
        // TODO: error handle
        dispatch(updateSharedReportError(error.message))
      })
  }

/**
 * get shared report for the given user id
 * @param {string} shareUid
 */
export const getSharedDashboard = (shareUid) => (dispatch) => {
  // TODO: add a new method
  dispatch(requestSharedDashboard())
  let update_function = myFirebase.functions().httpsCallable('getSharedReport')
  update_function({ shareUid: shareUid })
    .then((data) => {
      // TODO: push the data into store
      dispatch(requestSharedDashboardSuccess(data))
    })
    .catch((error) => {
      console.log('Shared report', error)
      // TODO: error handle
      dispatch(requestSharedDashboardError(error.message))
    })
}

/**
 * get a list of users that I shared report to
 *
 * TODO: let's limit the number of report to share as 3 per user.
 */
export const getListUserSharedReportToMe = (uid) => (dispatch) => {
  // TODO: add a new method for dispatch

  myFirebase
    .firestore()
    .collection('users')
    .doc(uid)
    .collection('shareTo')
    .get()
    .then((querySnapshot) => {
      var shareTo = []

      querySnapshot.forEach((doc) => {
        shareTo.push(doc.data())
      })
      dispatch(saveSharedTo(shareTo))
    })
    .catch((error) => {
      console.log('error shareTo', error)
      // TODO: handle the error
      dispatch(profileUpdateError(error.message))
    })
}

/**
 * get a list of users that they have been shared with me
 *
 * TODO: add pagination into this function later, because as a provider, multiple
 * people can share the report with them
 */
export const getListUserShareReportFromOthers = (uid) => (dispatch) => {
  // TODO: add a new method for dispatch
  myFirebase
    .firestore()
    .collection('users')
    .doc(uid)
    .collection('shareFrom')
    .get()
    .then((querySnapshot) => {
      var shareFrom = []

      querySnapshot.forEach((doc) => {
        shareFrom.push(doc.data())
      })
      // TODO: add shareFrom data into store
      dispatch(saveSharedFrom(shareFrom))
    })
    .catch((error) => {
      console.log('Share from err', error)
      // TODO: handle the error
      dispatch(profileUpdateError(error.message))
    })
}

/**
 * search the shared report through firestore
 *
 * This is design to search for users that shared their report to YOU only.
 * Because we have a limitation for how many users YOU can share, but no
 * limit how many users you can recieve their shared reports.
 *
 * Note: the search function may not work well, we can revisit it later.
 * @param {string} uid required
 * @param {string} text required
 * @returns
 */
export const searchShareReportByAlias = (uid, text) => (dispatch) => {
  dispatch(requestSharedByDashboard())
  let searchField = ''
  let startField = ''
  const trim_text = text.trim()

  if (text.includes('@')) {
    searchField = 'email'
    startField = trim_text.toLocaleLowerCase()
  } else {
    searchField = 'alias'
    startField = trim_text.toLocaleUpperCase()
  }

  // Ref: https://medium.com/feedflood/filter-by-search-keyword-in-cloud-firestore-query-638377bf0123
  myFirebase
    .firestore()
    .collection('users')
    .doc(uid)
    .collection('shareFrom')
    .orderBy(searchField)
    .startAt(startField)
    .endAt(trim_text.toLocaleLowerCase() + '\uf8ff')
    .get()
    .then((querySnapshot) => {
      let results = []

      querySnapshot.forEach((doc) => {
        results.push(doc.data())
      })

      if (results.length > 0) {
        dispatch(requestSharedByDashboardSuccess(results))
      } else {
        dispatch(
          requestSharedByDashboardError('No users matched your criteria')
        )
      }
    })
    .catch((error) => {
      console.log('Results err', error)
      // TODO: handle the error
      dispatch(requestSharedByDashboardError('No users matched your criteria'))
    })
}

export const getProfile = (uid) => (dispatch) => {
  dispatch(requestProfile())
  myFirebase
    .firestore()
    .collection('users')
    .doc(uid)
    .get()
    .then((doc) => {
      if (doc.exists) {
        dispatch(receiveProfile(doc.data()))
      } else {
        console.log('No data presented for ' + uid)
        dispatch(profileLoadError('No profile data exist for current user'))
      }
    })
    .catch((error) => {
      console.log(error)
      dispatch(profileLoadError(error.message))
    })
}

export const updateProfile = (data) => (dispatch) => {
  dispatch(requestProfileUpdate())
  let update_function = myFirebase
    .functions()
    .httpsCallable('updateUserProfile')
  update_function(data)
    .then(() => {
      dispatch(saveProfile(data))
    })
    .catch((error) => {
      console.log(error)
      dispatch(profileUpdateError(error.message))
    })
}
